import MentalHealthPillars from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthPillars";
import MentalHealthTopics from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthTopics";
import TriagingBlockSection from "@olivahealth/graphql-server/src/domain/value-objects/TriagingBlockSection";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { StartTherapySections } from "../MatchingQuestionnaire/data";

export enum TriagingNonPillarSections {
  INTRODUCTION = "INTRODUCTION",
  PERSONAL_PROFILE = "PERSONAL_PROFILE",
}

export const ProfessionalCoachingSideBarOrderedSections: SideBarSection[] = [
  {
    section: StartTherapySections.INTRODUCTION,
    isShown: false,
  },
  {
    section: StartTherapySections.PROFESSIONAL_COACHING_GOALS,
    isShown: true,
  },
  {
    section: StartTherapySections.AWAIT_MATCHES_GENERATED,
    isShown: true,
  },
];

// We want to hide some sections in the sidebar to make the questionnaire look shorter
export const StartTherapySideBarOrderedSections: SideBarSection[] = [
  {
    section: StartTherapySections.INTRODUCTION,
    isShown: false,
  },
  {
    section: StartTherapySections.PERSONAL_DATA_IF_MISSING,
    isShown: true,
  },
  {
    section: StartTherapySections.REFINEMENT_QUESTIONS,
    isShown: true,
  },
  {
    section: StartTherapySections.THERAPY_PREFERENCES,
    isShown: true,
  },
  {
    section: StartTherapySections.AWAIT_MATCHES_GENERATED,
    isShown: true,
  },
];

export type TriagingSectionsType =
  | TriagingNonPillarSections
  | TriagingBlockSection
  | StartTherapySections;

export const TriagingOrderedSections: TriagingSectionsType[] = [
  TriagingNonPillarSections.INTRODUCTION,
  TriagingBlockSection.PROFESSIONAL,
  TriagingBlockSection.SOCIAL,
  TriagingBlockSection.EMOTIONAL,
  TriagingBlockSection.PHYSICAL,
  TriagingBlockSection.FINANCIAL,
  TriagingNonPillarSections.PERSONAL_PROFILE,
];

export const ProfessionalCoachingNoWellbeingTriagingOrderedSections: TriagingSectionsType[] =
  [TriagingBlockSection.PROFESSIONAL_COACHING_GOALS];

export interface SideBarSection {
  section: TriagingSectionsType;
  isShown: boolean;
}

export const TriagingSideBarSections: SideBarSection[] =
  TriagingOrderedSections.map((section) => ({
    section,
    isShown: true,
  }));

export interface PillarData {
  pillar: MentalHealthPillars;
  colorHex: string;
  colorRebrand: "blue" | "default" | "green" | "orange" | "pink" | "purple";
  classnames: {
    text: string;
    border: string;
    hover: string;
    active: string;
    hoverActive: string;
    disabled: string;
  };
  topics: MentalHealthTopics[];
}

export type GetTriagingTopicsData = {
  [_key in MentalHealthPillars]: PillarData;
};

const commonClassNames = {
  text: "text-neutral-900",
  border: "border-blue-200",
  hover: "hover:bg-blue-100",
  active: "bg-blue-500 border-blue-500 !text-white",
  hoverActive: "hover:bg-opacity-75",
  disabled: "pointer-events-none opacity-50",
  width: "w-full",
};

export const getTriagingTopicsData: GetTriagingTopicsData = {
  [MentalHealthPillars.EMOTIONAL]: {
    pillar: MentalHealthPillars.EMOTIONAL,
    colorHex: tokens.colors["purple"][400],
    colorRebrand: "purple",
    classnames: commonClassNames,
    topics: [
      MentalHealthTopics.ADJUSTMENT_DISORDER,
      MentalHealthTopics.ANGER,
      MentalHealthTopics.ANXIETY,
      MentalHealthTopics.ATTENTION_AND_HYPERACTIVITY_CONCERNS,
      MentalHealthTopics.BEREAVEMENT_REACTION,
      MentalHealthTopics.BI_POLAR_DEPRESSION,
      MentalHealthTopics.BODY_DYSMORPHIA,
      MentalHealthTopics.BREAKING_HABITS,
      MentalHealthTopics.CULTURAL_IDENTITY,
      MentalHealthTopics.DEPRESSION,
      MentalHealthTopics.FEELING_DEPRESSED_LOW_MOOD,
      MentalHealthTopics.FINDING_PURPOSE,
      MentalHealthTopics.GAMBLING_NON_SUBSTANCE_ADDICTIONS,
      MentalHealthTopics.GENERALIZED_ANXIETY_DISORDER,
      MentalHealthTopics.GRIEF_LOSS,
      MentalHealthTopics.INDECISION,
      MentalHealthTopics.LACK_OF_CONFIDENCE,
      MentalHealthTopics.OBSESSIONS_AND_COMPULSIONS,
      MentalHealthTopics.OCD,
      MentalHealthTopics.PANIC_DISORDER_WITH_AGORAPHOBIA,
      MentalHealthTopics.PANIC_DISORDER_WITHOUT_AGORAPHOBIA,
      MentalHealthTopics.PERFECTIONISM,
      MentalHealthTopics.PHOBIAS,
      MentalHealthTopics.PROCRASTINATION,
      MentalHealthTopics.PTSD,
      MentalHealthTopics.RUMINATION,
      MentalHealthTopics.SELF_ESTEEM,
      MentalHealthTopics.SERIOUS_MENTAL_ILLNESS,
      MentalHealthTopics.SIGNIFICANT_OR_CHRONIC_STRESS,
      MentalHealthTopics.SKIN_PICKING,
      MentalHealthTopics.SUBSTANCE_ALCOHOL_USE_CONCERNS,
      MentalHealthTopics.TRAUMA,
      MentalHealthTopics.TRICHOTILLOMANIA,
      MentalHealthTopics.WORRY_ABOUT_BURNOUT,
      MentalHealthTopics.WORRY_ABOUT_PERFORMANCE_AT_WORK,
      MentalHealthTopics.UNHAPPY_AT_WORK,
      MentalHealthTopics.HELP_WITH_ADOPTING_HEALTHIER_HABITS,
    ],
  },
  [MentalHealthPillars.PROFESSIONAL]: {
    pillar: MentalHealthPillars.PROFESSIONAL,
    colorHex: tokens.colors["pink"][400],
    colorRebrand: "pink",
    classnames: commonClassNames,
    topics: [
      MentalHealthTopics.BEING_A_MANAGER_FOR_THE_FIRST_TIME,
      MentalHealthTopics.BEING_A_MANAGER_ISSUES,
      MentalHealthTopics.BEING_PROMOTED,
      MentalHealthTopics.BURNOUT,
      MentalHealthTopics.CAREER_CHANGE,
      MentalHealthTopics.COMMUNICATION_ISSUES_AT_WORK,
      MentalHealthTopics.DEALING_WITH_UNCERTAINTY_AT_WORK,
      MentalHealthTopics.DECISION_MAKING,
      MentalHealthTopics.DIVERSITY_EQUITY_INCLUSION_AT_WORK,
      MentalHealthTopics.GIVING_RECEIVING_FEEDBACK,
      MentalHealthTopics.IMPOSTER_SYNDROME,
      MentalHealthTopics.INTERPERSONAL_ISSUES,
      MentalHealthTopics.RECENT_PROMOTION,
      MentalHealthTopics.REMOTE_WORK_ISSUES,
      MentalHealthTopics.STRESS_AND_WORRY_AT_WORK,
      MentalHealthTopics.WORK_LIFE_BALANCE,
      MentalHealthTopics.HELP_WITH_WORK_RELATED_ISSUE,
    ],
  },
  [MentalHealthPillars.PHYSICAL]: {
    pillar: MentalHealthPillars.PHYSICAL,
    colorHex: tokens.colors["blue"][300],
    colorRebrand: "blue",
    classnames: commonClassNames,
    topics: [
      MentalHealthTopics.CHRONIC_PAIN,
      MentalHealthTopics.EATING_WEIGHT_ISSUES,
      MentalHealthTopics.HEALTH_ANXIETY,
      MentalHealthTopics.INSOMNIA,
      MentalHealthTopics.LACK_OF_EXERCISE,
      MentalHealthTopics.LONG_TERM_HEALTH_CONDITION,
      MentalHealthTopics.MEDICALLY_UNEXPLAINED_SYMPTOMS,
      MentalHealthTopics.MINDFULNESS_MEDITATION,
      MentalHealthTopics.QUITTING_SMOKING,
      MentalHealthTopics.SLEEP_ISSUES,
    ],
  },
  [MentalHealthPillars.SOCIAL]: {
    pillar: MentalHealthPillars.SOCIAL,
    colorHex: tokens.colors["orange"][300],
    colorRebrand: "orange",
    classnames: commonClassNames,
    topics: [
      MentalHealthTopics.ASSERTIVENESS,
      MentalHealthTopics.BEING_A_CARER,
      MentalHealthTopics.BUILDING_NEW_RELATIONSHIPS,
      MentalHealthTopics.BULLYING,
      MentalHealthTopics.DIVERSITY_EQUITY_INCLUSION,
      MentalHealthTopics.IMPROVING_MY_RELATIONSHIPS,
      MentalHealthTopics.MANAGING_CONFLICT,
      MentalHealthTopics.NAVIGATING_CONFLICT,
      MentalHealthTopics.PARENTING_CAREGIVING_FAMILY,
      MentalHealthTopics.SEXUAL_DYSFUNCTION,
      MentalHealthTopics.SOCIAL_ANXIETY,
      MentalHealthTopics.SPEAKING_IN_FRONT_OF_OTHERS,
    ],
  },
  [MentalHealthPillars.FINANCIAL]: {
    pillar: MentalHealthPillars.FINANCIAL,
    colorHex: tokens.colors["green"][700],
    colorRebrand: "green",
    classnames: commonClassNames,
    topics: [
      MentalHealthTopics.COMPULSIVE_BUYING,
      MentalHealthTopics.DEALING_WITH_DEBT,
      MentalHealthTopics.DEALING_WITH_FINANCIAL_WORRY,
      MentalHealthTopics.FINANCIAL_ANXIETY,
      MentalHealthTopics.FINANCIAL_STABILITY,
      MentalHealthTopics.FINANCIAL_PRESSURE_FROM_FAMILY_MEMBERS,
    ],
  },
};

export const getCoachingTopicsData = () => ({
  colorHex: tokens.colors["purple"][400],
  colorRebrand: "purple",
  classnames: commonClassNames,
});

export const getTriagingPillarsColorNames = {
  [MentalHealthPillars.EMOTIONAL]: "text-purple-500",
  [MentalHealthPillars.PROFESSIONAL]: "text-pink-400",
  [MentalHealthPillars.PHYSICAL]: "text-accent-blue-500",
  [MentalHealthPillars.SOCIAL]: "text-orange-500",
  [MentalHealthPillars.FINANCIAL]: "text-green-500",
};

export const getTriagingPillarsRebrandVariants = {
  [MentalHealthPillars.EMOTIONAL]: "purple",
  [MentalHealthPillars.PROFESSIONAL]: "pink",
  [MentalHealthPillars.PHYSICAL]: "blue",
  [MentalHealthPillars.SOCIAL]: "orange",
  [MentalHealthPillars.FINANCIAL]: "green",
};

enum AnswerOptions {
  NOT_AT_ALL = "NOT_AT_ALL",
  SEVERAL_DAYS = "SEVERAL_DAYS",
  OVER_HALF_THE_DAYS = "OVER_HALF_THE_DAYS",
  NEARLY_EVERY_DAY = "NEARLY_EVERY_DAY",
}

export const getAnswerOptions = [
  AnswerOptions.NOT_AT_ALL,
  AnswerOptions.SEVERAL_DAYS,
  AnswerOptions.OVER_HALF_THE_DAYS,
  AnswerOptions.NEARLY_EVERY_DAY,
];

export const EXAMPLE_SCORES = [
  {
    score: 96,
    pillar: MentalHealthPillars.PROFESSIONAL,
  },
  {
    score: 88,
    pillar: MentalHealthPillars.SOCIAL,
  },
  {
    score: 48,
    pillar: MentalHealthPillars.EMOTIONAL,
  },
  {
    score: 48,
    pillar: MentalHealthPillars.PHYSICAL,
  },
  {
    score: 92,
    pillar: MentalHealthPillars.FINANCIAL,
  },
];
